import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { de } from '@locales/index';
import format from '@utilities/I18n/format';

i18n.use(initReactI18next).init({
  resources: {
    de,
  },
  lng: 'de',
  fallbackLng: 'de',

  interpolation: {
    format,
    escapeValue: false,
  },
});

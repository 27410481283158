import { Button, Modal } from '@mantine/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNetworkState } from 'react-use';

export const OfflineIndicator: FunctionComponent = () => {
  const { t } = useTranslation('common');
  const state = useNetworkState();

  if (state.online) {
    return null;
  }

  return (
    <Modal
      opened={true}
      title={t('offlineIndicator.title')}
      onClose={undefined}
      hideCloseButton={true}
    >
      <Button>{t('offlineIndicator.content')}</Button>
    </Modal>
  );
};

import { formatCurrency } from 'src/utilities/Format/currency';
import {
  formatDate,
  formatDateDistanceToNow,
  formatDateRelative,
  formatDateTime,
  formatTime,
} from 'src/utilities/Format/date';
import { formatNumber } from 'src/utilities/Format/number';

const format = (value: any, format?: string, lng?: string) => {
  const lang: string = lng ? lng : 'de';
  const langISO = `${lang}-${lang.toUpperCase()}`;

  if (format === 'currency') {
    return formatCurrency(value, langISO);
  } else if (format === 'number') {
    return formatNumber(value, 2, langISO);
  } else if (format === 'datetime') {
    return formatDateTime(value, {}, langISO);
  } else if (format === 'date') {
    return formatDate(value, {}, langISO);
  } else if (format === 'time') {
    return formatTime(value, {}, langISO);
  } else if (format === 'dateRelative') {
    return formatDateRelative(value, new Date(), langISO);
  } else if (format === 'dateDistanceToNow') {
    return formatDateDistanceToNow(value, langISO);
  }

  return value;
};

export default format;

import { formatDistanceToNow, formatRelative } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import de from 'date-fns/locale/de';

type PossibleDate = Date | string | number;

export const getDateFromValue = (value?: PossibleDate): Date | undefined => {
  let date: Date | undefined;

  if (typeof value === 'string' || typeof value === 'number') {
    try {
      date = utcToZonedTime(value, 'Europe/Berlin');
    } catch (e: any) {
      date = undefined;
    }
  } else if (value instanceof Date) {
    date = value;
  }

  return date;
};

export const formatDateTime = (
  value: PossibleDate,
  options: Intl.DateTimeFormatOptions = {},
  lang = 'de-DE'
) => {
  const date = getDateFromValue(value);

  if (!date) {
    return undefined;
  }

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    ...options,
  };

  return new Intl.DateTimeFormat(lang, dateOptions).format(date);
};

export const formatTime = (
  value: PossibleDate,
  options: Intl.DateTimeFormatOptions = {},
  lang?: string
) => {
  return formatDateTime(
    value,
    {
      year: undefined,
      month: undefined,
      day: undefined,
      ...options,
    },
    lang
  );
};

export const formatDate = (
  value: PossibleDate,
  options: Intl.DateTimeFormatOptions = {},
  lang?: string
) => {
  return formatDateTime(
    value,
    {
      hour: undefined,
      minute: undefined,
      ...options,
    },
    lang
  );
};

export const formatDateRelative = (
  value: PossibleDate,
  baseDate?: PossibleDate,
  lang?: string
) => {
  const date = getDateFromValue(value);
  const bDate = getDateFromValue(baseDate) || new Date();

  if (!date) {
    return null;
  }

  return formatRelative(date, bDate, {
    locale: de,
  });
};

export const formatDateDistanceToNow = (
  value: PossibleDate,
  lang?: string,
  includeSeconds = false
) => {
  const date = getDateFromValue(value);

  if (!date) {
    return null;
  }

  return formatDistanceToNow(date, {
    locale: de,
    addSuffix: true,
    includeSeconds,
  });
};

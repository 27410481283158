export const formatNumber = (
  value: number | string,
  maximumFractionDigits = 2,
  lang = 'de-DE'
) => {
  if (value === null || value === undefined || value === '') {
    return null;
  }

  const num =
    typeof value === 'string' ? Number.parseFloat(value) : (value as number);

  return new Intl.NumberFormat(lang, {
    maximumFractionDigits,
  }).format(num);
};

import { library } from '@fortawesome/fontawesome-svg-core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import {
  faArchive,
  faBan,
  faBars,
  faBroadcastTower,
  faCalendarDay,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faCog,
  faCopy,
  faCubes,
  faEdit,
  faEllipsisV,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFile,
  faHome,
  faIdCard,
  faInfoCircle,
  faListOl,
  faMinus,
  faMinusCircle,
  faPaperPlane,
  faPlay,
  faPlus,
  faPlusCircle,
  faShare,
  faShareAlt,
  faSignInAlt,
  faSignOutAlt,
  faSpinner,
  faStop,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrophy,
  faUpload,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faPlus,
  faBars,
  faIdCard,
  faCheckCircle,
  faUser,
  faUsers,
  faEdit,
  faPaperPlane,
  faSpinner,
  faExclamationTriangle,
  faTimes,
  faTimesCircle,
  faInfoCircle,
  faBan,
  faHome,
  faTrophy,
  faCog,
  faCubes,
  faListOl,
  faCopy,
  faWhatsapp,
  faPlay,
  faPlusCircle,
  faMinus,
  faMinusCircle,
  faStop,
  faBroadcastTower,
  faSignInAlt,
  faSignOutAlt,
  faArchive,
  faFile,
  faExternalLinkAlt,
  faChevronRight,
  faTrash,
  faCalendarDay,
  faCheck,
  faUpload,
  faEllipsisV,
  faShare,
  faShareAlt
);

export const formatCurrency = (
  value: number | string,
  currency = 'EUR',
  lang = 'de-DE',
  negative = false
) => {
  let num = 0;

  if (value === null || value === undefined || value === '') {
    return null;
  }

  if (typeof value === 'string') {
    num = Number.parseFloat(value);
  } else {
    num = value;
  }

  if (negative) {
    num *= -1;
  }

  return new Intl.NumberFormat(lang, {
    style: 'currency',
    currency,
  }).format(num);
};

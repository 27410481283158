import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useEffect } from 'react';
import { QueryClientProvider } from 'react-query';

// eslint-disable-next-line import/order
import '@styles/globals.css';
import '@utilities/i18n';
import '@utilities/icons';

import { OfflineIndicator } from '@components/Ui/OfflineIndicator';
import queryClient from '@utilities/query';

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      'serviceWorker' in navigator &&
      // @ts-ignore
      window.workbox !== undefined
    ) {
      // @ts-ignore
      const wb = window.workbox;

      // A common UX pattern for progressive web apps is to show a banner when a service worker has updated and waiting to install.
      // NOTE: MUST set skipWaiting to false in next.config.js pwa object
      // https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
      const promptNewVersionAvailable = () => {
        // `event.wasWaitingBeforeRegister` will be false if this is the first time the updated service worker is waiting.
        // When `event.wasWaitingBeforeRegister` is true, a previously updated service worker is still waiting.
        // You may want to customize the UI prompt accordingly.
        if (
          confirm('Eine neue Version ist verfügbar! Jetzt die Seite neu laden.')
        ) {
          wb.addEventListener('controlling', () => {
            window.location.reload();
          });

          // Send a message to the waiting service worker, instructing it to activate.
          wb.messageSkipWaiting();
        } else {
          console.log(
            'User rejected to reload the web app, keep using old version. New version will be automatically load when user open the app next time.'
          );
        }
      };

      wb.addEventListener('waiting', promptNewVersionAvailable);
    }
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <MantineProvider
        withGlobalStyles={true}
        withNormalizeCSS={true}
        theme={{
          colorScheme: 'light',
          //colorScheme: 'dark',
          primaryColor: 'dark',
          spacing: {
            xs: 5,
            xl: 32,
          },
          fontSizes: {
            xl: 30,
          },
          colors: {
            white: [
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
            ],
          },
        }}
      >
        <ModalsProvider>
          <NotificationsProvider position="top-right">
            <OfflineIndicator />
            <QueryClientProvider client={queryClient}>
              <Component {...pageProps} />
            </QueryClientProvider>
          </NotificationsProvider>
        </ModalsProvider>
      </MantineProvider>
    </>
  );
}

export default MyApp;
